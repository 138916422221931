.predictions {
  list-style: none;
  margin: 0;
  padding: 0;

  .prediction {
    font-size: 14px;
    line-height: 16px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    .confidence {
      display: flex;
      align-items: center;
      margin-top: 4px;

      .progressContainer {
        flex: 1;
        height: 12px;
        margin-right: 8px;
        overflow: hidden;
        border-radius: 8px;
        background-color: #E0E0E0;

        .progress {
          height: 100%;
          border-radius: 8px;
          background-color: #00BDC7;
        }
      }
    }

    .parts {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .partsIcon {
        margin-right: 8px;
      }
    }
  }
}