.titles {
  display: flex;
  padding: 8px 16px;
  background: #F2F2F2;

  &> * {
    flex: 1;
  }

  h2 {
    color: #515457;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
    padding: 0 0 0 8px;
  }

  .symptomSearchTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.container {
  display: flex;
  padding: 16px 16px 32px 16px;

  & > * {
    width: calc(100% / 3);

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}