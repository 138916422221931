.toggle {
  border: 0;
  padding: 0;
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}