$main-border: 1px solid #C7C5BD;

.container {
  border: $main-border;
  display: flex;
  flex-direction: column;

  .row {
    flex: 1;
    display: flex;
    align-items: stretch;

    &:not(:last-child) {
      border-bottom: $main-border;
    }

    .label, .value {
      display: flex;
      align-items: center;
    }

    .label {
      width: 35%;
      border-left: 4px solid #2090A2;
      border-right: $main-border;
      font-size: 14px;
      line-height: 20px;
      color: #515457;
      font-weight: 700;
      padding: 8px;
    }

    .value {
      padding: 8px 16px;
    }

    .value, .listValue {
      width: 65%;
    }

    .listValue {
      margin: 0;
      padding: 16px 16px 16px 32px;
    }
  }
}