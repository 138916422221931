.container {
  display: flex;
  flex-direction: column;

  .searchField {
    flex: 1;
    padding: 12px;
    border: 1px solid #C7C5BD;
    border-left: 4px solid #F4BE37;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;

    &:disabled {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .btn {
    border: 1px solid #00bdc7;
    border-radius: 12px;
    color: #00bdc7;
    background-color: transparent;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;

    &:disabled {
      color: rgba(0, 0, 0, 0.2);
      border-color: rgba(0, 0, 0, 0.2);
      background-color: rgba(0, 0, 0, 0.03);
      cursor: none;
    }
  }
}